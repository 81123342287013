import React from "react";
import ImageContainer from "./ImageContainer";



const Portfolio = (props) => {
  return (
    <section class="page-section bg-light" id="portfolio">
      <div class="container">
        <div class="text-center">
          <h2 class="section-heading text-uppercase">Portfolio</h2>
          <h3 class="section-subheading text-muted">
            <p>
              Over last 12 years, we made multiple temples of different sizes and shapes.
            </p>
            <p>
              Size ranges from (30cm Width X 25cm Depth X 40cm Height) to (180cm width X  80cm depth X 130cm Height).
            </p>
          </h3>
          <div>
            <ImageContainer />
          </div>
        </div>
      </div>
    </section>
  );
}


export default Portfolio;
