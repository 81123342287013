import React from "react";
const Contact = (props) => {
    return (
      <section className="page-section" id="contact">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase">Contact Us</h2>
            <h3 className="text-center text-white">
              For any further details please contact us
            </h3>
            <h3 className="text-center text-muted">E-mail</h3>
            <h3 className="text-center text-bright">
              my.small.temples@gmail.com
            </h3>
            <h3 className="text-center text-muted">
              Phone 
            </h3>
            <h3 className="text-center text-bright">
              0447 829 021
            </h3>
          </div>
        </div>
      </section>
    );
  }

export default Contact;
