import React from "react";
import Header from "../common/Header";
import Portfolio from "../common/Portfolio";
import Colours from "../pages/Colours";
import Contact from "../pages/Contact";

// External Pages
import Services from "../common/Services";

const Home = (props) => {
  return (
    <div>
      <Header
        title="Welcome to small-temples"
        subtitle="Customised temples"
        buttonText="Find out more..."
        link="./services"
        showButton={true}
      />
      <Services />
      <Portfolio />
      <div>
        <h3 class="section-heading text-center"> Select colors </h3>
        <Colours />
      </div>
      <Contact />
    </div>
  );
};

export default Home;
