import React from 'react';
import { Link } from 'react-router-dom';

const Header = (props) => {
  return (
    <header
      className="masthead"
    >
      <div className="container">
        <div className="masthead-subheading">{props.title}</div>
        <div className="masthead-heading text-uppercase">
          {props.subtitle}
        </div>
        {props.showButton && (
          <Link
            className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
            to={props.link}
          >
            {props.buttonText}
          </Link>
        )}
      </div>
    </header>
  );
}


export default Header;