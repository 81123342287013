import React from "react";
import SingleService from "./SingleService";

const details =
  "In India every homes has a place, a mandir or temple  where you pray. It is a place in your home where you feel relaxed, get some peace and feel assured. Around 2008, I found it difficult to get small temple (Pooja Mandir) in Australia as per my own requirements. The temples available in Indian Shops or malls are either too small and also too costly. Now Small-Temples Australia are bringing you temples of your choice, design, size, color and it is all at a very reasonable cost. Till date we have made number of pooja mandirs across Melbourne for our community. If you are interested, please be in touch with us by email or phone.";

const serviceItem = [
  {
    icon: "fa-ruler",
    title: "Decide Temple Size",
    description:
      "Decide what width(W) X depth(D) X height(H) of the temple you require, according to the available space",
  },
  {
    icon: "fa-clipboard",
    title: "Select design",
    description:
      "There are multiple designs available in our gallary. Decide which suites your requirement. Please contact us if you have any other requirements",
  },
  {
    icon: "fa-phone-volume",
    title: "Contact us",
    description:
      "You can contact us by email, phone or whats-app and provide all the details",
  },
  {
    icon: "fa-drafting-compass",
    title: "Plan and sketch",
    description:
      "We will provide a sketch of the temple and decide final requirements including color, cost, etc.",
  },
  {
    icon: "fa-gopuram",
    title: "Build",
    description:
      "Generally it takes couple of weeks to build the temple as per the sketch. We will keep you informed about it.",
  },
  {
    icon: "fa-truck-pickup",
    title: "Pick-up or Deliver",
    description:
      "Once ready you can pick up the temple from our place. Depending on the size, distance, we can deliver it to your place as well",
  },
];

const Services = (props) => {
  return (
    <section class="page-section" id="services">
      <div class="container">
        <div class="text-center">
          <h2 class="section-heading ">Our Services</h2>
          <h3 class="section-subheading text-muted">
            <p> {`${details}`} </p>
          </h3>
        </div>
        <div class="row text-center">
          {serviceItem.map((item, index) => {
            return <SingleService {...item} key={index} />;
          })}
        </div>
      </div>
      <h3 class="section-heading text-center">Find Dimensions - some examples</h3>
      <table className="table table-bordered table-dark text-center">
        <thead>
          <tr>
            <th> Width (cm)</th>
            <th> Depth (cm)</th>
            <th> Height (cm)</th>
            <th> Shikhara / Kalas layers (cm)</th>
            <th> Specific color</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> 50cm </td>
            <td> 40cm </td>
            <td> 70cm </td>
            <td> 2 layers </td>
            <td> Jarah </td>
          </tr>
          <tr>
            <td> 75cm </td>
            <td> 50cm </td>
            <td> 90cm </td>
            <td> 3 layers </td>
            <td> Coco black </td>
          </tr>
          <tr>
            <td> 120cm </td>
            <td> 70cm </td>
            <td> 120cm </td>
            <td> 4 layers </td>
            <td> Cedar </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}


export default Services;
