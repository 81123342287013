import React from "react";
import { Carousel } from "react-responsive-carousel";

const Colours = (props) => {
    return (
      <Carousel
        showArrows={true}>
        <div>
          <img src="../assets/image/color/colorImage1.jpg" alt="loading" />
          <p className="legend"> Color Set 1 </p>
        </div>
        <div>
          <img src="../assets/image/color/colorImage2.jpg" alt="loading" />
          <p className="legend"> Color Ste 2 </p>
        </div>
        <div>
          <img src="../assets/image/color/colorImage3.jpg" alt="loading" />
          <p className="legend"> Color Set 3 </p>
        </div>
      </Carousel>
    );
  }

export default Colours;
