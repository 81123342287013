import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
// import "./App.css";
import PageWrapper from "./component/PageWrapper";
import Home from "./component/pages/Home";
import Colours from "./component/pages/Colours";
import Services from "./component/common/Services";
import Portfolio from "./component/common/Portfolio";
import Contact from "./component/pages/Contact";

class App extends Component {
  render() {
    return (
      <Router>
        <PageWrapper>
          <Route exact={true} path="/" component={Home} />
          <Route path="/services" component={Services} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/colours" component={Colours} />
          <Route path="/contact" component={Contact} />
        </PageWrapper>
      </Router>
    );
  }
}

export default App;
